import { useContext, useState } from "react";
import AdminDrawer from "../AdminDrawer";
import Header from "../Header";
import { AuthContext } from "../../contexts/AuthProvider";
import { Box } from "@mui/material";

export default function Layout({ children }) {
    const { user } = useContext(AuthContext);
    const [isVisible, setIsVisible] = useState(false);

    const handleVisibilityChange = () => {
        setIsVisible(!isVisible);
    };

    const drawerWidth = '240px';

    if (user) {
        return (
            <Box sx={{
                ml: {
                    xs: 0,
                    md: drawerWidth
                }
            }} mt={'4rem'} p={'1rem'}>
                <Header handleVisibilityChange={handleVisibilityChange} />
                <AdminDrawer isVisible={isVisible} handleVisibilityChange={handleVisibilityChange} />
                {children}
            </Box>
        );
    };

    return children;
};
