import { useState, useEffect } from 'react';
import { Breadcrumbs, Card, Grid2, Link as MuiLink, IconButton, Typography, Box } from "@mui/material";
import { FaGem, FaMagnifyingGlass, FaPen } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getAdminUsers } from '../../helpers/http_admin_users_requests';
import { CustomDataTable } from '../../components/common/CustomDataTable';
import ListingPage from '../../components/DynamicListingPage';

export default function Products() {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        getAdminUsers(setProducts)
    }, []);

    const columns = [
        { field: 'image', headerName: 'Foto', },
        {
            field: 'complete_name',
            headerName: 'Definir',
        },
        {
            field: 'whatsapp_number',
            headerName: 'Definir',
        },
        {
            field: 'cnpj',
            headerName: 'Definir',
            type: 'Definir',
        },
        {
            field: 'whatsapp_number',
            headerName: 'Definir',
        },
        {
            field: 'actions',
            headerName: 'Ações',
            type: 'number',
        },
    ];

    const renderActions = (row) => (
        <>
            <Link to={`/detalhes-produto/${row.id}`}>
                <IconButton title="Detalhes" color="primary">
                    <FaMagnifyingGlass size={20} />
                </IconButton>
            </Link>
            <Link to={`/editar-produto/${row.id}`}>
                <IconButton title="Edição" color="primary">
                    <FaPen size={20} />
                </IconButton>
            </Link>
        </>
    );

    return <ListingPage icon={FaGem} pageTitle={"Produtos Inovart Gold"} addButtonTitle={"produtos"} addPath={'/novo-produto'} columns={columns} data={products} actions={renderActions} />
};