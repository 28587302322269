import toast from "react-hot-toast";
import { api } from "../services/api";
import { Typography } from "@mui/material";


export function getAdminUsers(setData) {

    api.get('/admin-user.php')
        .then(response => {

            const { data } = response;

            if (data.success) {
                setData(data.admin_users);
            } else {
                toast.error('Houve um erro ao carregar o conteúdo desta página!');
            };
        })
        .catch(error => {
            console.log(error);
            toast.error('Houve um erro ao carregar o conteúdo desta página!');
        });
};

export function getAdminUserDetails(id, setData) {

    api.get(`/admin-user.php?user_id=${id}`)
        .then(response => {

            const { data } = response;

            if (data.success) {
                setData(data.user_admin_data);
            } else {
                toast.error('Houve um erro ao carregar o conteúdo desta página!');
            };
        })
        .catch(error => {
            console.log(error);
            toast.error('Houve um erro ao carregar o conteúdo desta página!');
        });
};

export function createAdminUser(data, navigateBack) {

    api.post('/admin-user.php', data)
        .then(response => {

            const { data } = response;

            if (data.success) {
                toast.success(<Typography>Usuário criado com sucesso!</Typography>);
                navigateBack();
            } else {
                toast.error('Houve um erro criar esse usuário!');
            };
        })
        .catch(error => {
            console.log(error);
            toast.error('Houve um erro ao executar essa ação!');
        });
};

export function updateAdminUser(data, navigateBack) {

    api.patch('/admin-user.php', data)
        .then(response => {

            const { data } = response;

            if (data.success) {
                toast.success(<Typography>Usuário editado com sucesso!</Typography>);
                navigateBack();
            } else {
                toast.error('Houve um erro editar esse usuário!');
            };
        })
        .catch(error => {
            console.log(error);
            toast.error('Houve um erro ao executar essa ação!');
        });
};