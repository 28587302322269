import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";
import Login from "../pages/Login";

export default function RouteWrapper({ loggedComponent, defaultComponent, isPrivate }) {

    const { user } = useContext(AuthContext);

    if (user && !isPrivate) {

        return loggedComponent; //direciona para página privada.

    } else if (!user && isPrivate) {


        return <Login />; //direciona para página inicial.

    } else {

        return user ? loggedComponent : defaultComponent;
        
    };
};