import * as Yup from "yup";
import { useContext } from "react";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { AuthContext } from "../../contexts/AuthProvider";
import logo from '../../assets/logo.png';

const validationSchema = Yup.object({
    user: Yup.string().required("O campo de usuário é obrigatório"),
    password: Yup.string().min(5, "A senha deve ter pelo menos 6 caracteres").required("O campo de senha é obrigatório"),
});

export default function Login() {

    const { signIn } = useContext(AuthContext);

    return (
        <Box component="main" sx={styles.mainBox}>
            <Card sx={styles.card}>

                <Box component={'img'} borderRadius={'1rem'} src={logo} height={'6rem'} mb={'1rem'} />
                <Typography textAlign="center" variant="h4" component="h1" mb="1rem">Sistema de Gerenciamento - Inovart Gold</Typography>

                <Formik
                    initialValues={{ user: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        signIn(values.user, values.password);
                    }}
                >
                    {({ errors, touched, values }) => (
                        <Form>
                            <Field
                                as={TextField}
                                name="user"
                                label="Usuário"
                                variant="outlined"
                                fullWidth
                                sx={styles.textField}
                                helperText={touched.user && errors.user ? errors.user : ""}
                                error={touched.user && Boolean(errors.user)}
                                InputLabelProps={{
                                    shrink: values.user !== "",
                                }}
                            />

                            <Field
                                as={TextField}
                                name="password"
                                label="Senha"
                                variant="outlined"
                                type="password"
                                fullWidth
                                sx={styles.textField}
                                helperText={touched.password && errors.password ? errors.password : ""}
                                error={touched.password && Boolean(errors.password)}
                                InputLabelProps={{
                                    shrink: values.password !== "",
                                }}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={styles.textField}
                            >
                                Entrar na conta
                            </Button>
                            <Button disabled variant="text" fullWidth>
                                Redefinir senha
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Card>
        </Box>
    );
};

const styles = {
    mainBox: {
        bgcolor: 'grey',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        p: '2rem',
        m: '1rem'
    },
    textField: {
        mb: '1rem',
    },
};
