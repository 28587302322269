import { Button, CircularProgress } from "@mui/material";

export default function CustomButton({
    width = '14rem',
    height = '2.5rem',
    variant = "contained",
    title,
    isLoading = false,
    startIcon,
    ...rest
}) {


    const button_dimensions = {
        minWidth: width,
        minHeight: height,
    };

    return (
        <Button
            sx={button_dimensions}
            startIcon={!isLoading ? startIcon : null}
            disabled={isLoading}
            variant={variant}
            {...rest}
        >
            {isLoading ? (
                <CircularProgress color="grey" size={24} />
            ) : (
                title
            )}
        </Button>
    );
}
