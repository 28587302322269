import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useMediaQuery, useTheme, Box, Typography } from "@mui/material";
import { FaBuildingColumns, FaCartShopping, FaDollarSign, FaEnvelope, FaFile, FaGem, FaHouse, FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";

import logo from '../../assets/logo.png';

const nav_links = [
    { label: "Dashboard", path: "/", icon: FaHouse, disabled: false },
    { label: "Pedidos", path: "/pedidos", icon: FaCartShopping, disabled: false },
    { label: "Produtos", path: "/produtos", icon: FaGem, disabled: false },
    { label: "Cotação", path: "/cotacao", icon: FaDollarSign, disabled: false },
    {
        label: "Atr. de Produtos",
        icon: FaGem,
        collapse: true,
        disabled: true,
        sublinks: [
            { label: "Produtos", path: "/usuarios-administrativos", icon: FaUser, disabled: false },
            { label: "Categorias", path: "/categorias", icon: FaUser, disabled: false },
            { label: "Materiais", path: "/materiais", icon: FaUser, disabled: false },
            { label: "Formatos", path: "/Formatos", icon: FaUser, disabled: false },
            { label: "Confortos", path: "/confortos", icon: FaUser, disabled: false },
            { label: "Banhos", path: "/banhos", icon: FaUser, disabled: false },
            { label: "Acabamento Externo", path: "/acabamento-externo", icon: FaUser, disabled: false },
        ],
    },
    { label: "Pedras", path: "/pedras", icon: FaGem, disabled: true },
    { label: "Usuários", path: "/usuarios-administrativos", icon: FaUser, disabled: false },
    { label: "Clientes", path: "/clientes", icon: FaBuildingColumns, disabled: false },
    { label: "Relatórios", path: "/relatorios", icon: FaFile, disabled: true },
    { label: "Orçamentos", path: "/orcamentos", icon: FaEnvelope, disabled: false },
];


export default function AdminDrawer({ isVisible, handleVisibilityChange }) {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Drawer
            sx={{
                position: 'fixed',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                },
                zIndex: 1200
            }}
            variant={isMdUp ? "permanent" : "temporary"}
            anchor="left"
            open={isMdUp ? true : isVisible}
            onClose={() => handleVisibilityChange()}
        >
            <Toolbar sx={{
                bgcolor: '#231F20',
                display: 'flex',
                justifyContent: 'center'
            }} >
                <Box component={'img'} src={logo} height={'50px'} />
            </Toolbar>
            <Divider />
            <List>
                {nav_links.map((obj, index) => (
                    <ListItem disablePadding key={index} >
                        <ListItemButton sx={{ textDecoration: 'none', py: '.75rem' }} component={Link} to={obj.path} disabled={obj.disabled}>
                            <ListItemIcon sx={{ color: theme.palette.primary.light }}>
                                <obj.icon size={20} />
                            </ListItemIcon>
                            <Typography color="primary">
                                {obj.label}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};
