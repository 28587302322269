import toast from "react-hot-toast";
import { api } from "../services/api";
import { Typography } from "@mui/material";

export async function signInAdmin(user, password) {
    const payload = { user, password };

    try {
        const response = await api.post('/auth.php', payload);
        const { data } = response;

        if (data.token) {
            return data;
        }
    } catch (error) {
        console.log(error);
        toast.error(<Typography>Houve um erro ao carregar o conteúdo desta página!</Typography>);
    };
};

export async function getUserData(token) {
    try {
        const response = await api.get('/auth.php?user-token=' + token);
        const { data } = response;

        if (data) {
            return data;
        } else {
            toast.error(<Typography>Houve um erro ao carregar o conteúdo desta página!</Typography>);
        };

    } catch (error) {
        console.log(error);
        toast.error(<Typography>Houve um erro ao carregar o conteúdo desta página!</Typography>);
    };
};

