import { useState } from "react";
import { Box, Button, Card, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ProductDetails() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        complete_name: "",
        cpf: "",
        birth_date: "",
        whatsapp_number: "",
        email: "",
        title: "",
        observation: "",
        zip_code: "",
        address: "",
        address_number: "",
        compliment: "",
        neighborhood: "",
        city: "",
        state: "",
        profile_image: ""
    });

    return (
        <Grid2 component={'form'} container rowSpacing={3}>
            <Grid2 size={{ xs: 12 }}>
                <Typography mt={'1rem'} fontSize={24} variant="h1">
                    Detalhes de Produto
                </Typography>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Dados de Produto
                    </Typography>
                    <Grid2 container spacing={3}>
                        <Grid2 size={{ xs: 12 }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mb: '1rem'
                            }}>
                                {
                                    Array(3).fill('').map((_, index) => (
                                        <Box position="relative" key={index} display="inline-block" m={1}>
                                            <Box component={'img'} height={'125px'} width={'125px'} bgcolor={'blue'} />
                                        </Box>

                                    ))
                                }
                            </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Categoria</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Categoria"
                                    name="category"
                                    value={formData.category}
                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Material</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Material"
                                    name="material"
                                    value={formData.material}

                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Formato</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Formato"
                                    name="format"
                                    value={formData.format}

                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Conforto</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Conforto"
                                    name="confort"
                                    value={formData.confort}

                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Largura"
                                name="width"
                                value={formData.width}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Espessura"
                                name="thickness"
                                value={formData.thickness}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Peso"
                                type="number"
                                name="weight"
                                value={formData.weight}
                                InputLabelProps={{ shrink: true }}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Banho</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Banho"
                                    name="plating"
                                    value={formData.plating}

                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Acabamento externo</InputLabel>
                                <Select
                                    disabled
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Acabamento externo"
                                    name="external_finishing"
                                    value={formData.external_finishing}

                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <TextField
                                disabled
                                fullWidth
                                multiline
                                rows={6}
                                variant="standard"
                                label="Observação"
                                name="observation"
                                value={formData.observation}

                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Pedras
                    </Typography>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Ficha técnica do produto
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Nome"
                                name="name"
                                value={formData.name}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Referência Interna"
                                name="inner_reference"
                                value={formData.inner_reference}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                variant="standard"
                                label="Prazo de Confecção"
                                name="production_time"
                                value={formData.production_time}

                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <TextField
                                disabled
                                required
                                fullWidth
                                multiline
                                rows={6}
                                variant="standard"
                                label="Descrição"
                                name="description"
                                value={formData.description}

                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="text" sx={{ ml: 2 }} onClick={() => navigate(-1)}>
                        Voltar
                    </Button>
                </Box>
            </Grid2>
        </Grid2>
    );
};
