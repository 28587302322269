import { Box, Button, Card, Grid2, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { FaDollarSign, FaPen } from "react-icons/fa6";
import { moneyMask } from "../../utils/functions";
import toast from "react-hot-toast";

export default function PriceManagement() {

    const theme = useTheme();

    const [value, setValue] = useState('R$ 0,00');

    const handleChange = (e) => {

        setValue(moneyMask(e.target.value));

    };

    const handleUpdate = (e) => {

        toast.success(<Typography textAlign={'left'}>Cotação atualizada e disponível para todos clientes!</Typography>)

    };

    return (
        <Grid2 container>
            <Grid2 sx={{
                display: 'flex'
            }} size={12}>
                <Typography variant="h1" textAlign={'center'} mt={'1rem'} mb={'2rem'}>
                    <Box component={FaDollarSign} color={theme.palette.primary.light} size={32} mr={.75} />
                    Definição de Cotação Base
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <Card sx={{
                    p: '1rem'
                }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'start',
                        flexDirection: 'column',
                        mb: '1rem'
                    }}>
                        <Typography variant="subtitle1" color="primary" textAlign={'center'} mb={'.5rem'}>
                            Cotação atual:
                        </Typography>
                        <Typography variant="h2" fontWeight={600} color="success" textAlign={'center'}>
                            R$590,00
                        </Typography>
                    </Box>

                    <TextField value={value} onChange={handleChange} size="small" placeholder="Escrever novo valor" sx={{
                        mr: '1rem',
                        mb: {
                            xs: '1rem',
                            sm: '0',
                        },
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        }
                    }} />
                    <Button onClick={handleUpdate} startIcon={<FaPen />} variant="contained" color="secondary" sx={{
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}>
                        Atualizar cotação
                    </Button>
                </Card>
            </Grid2>
        </Grid2>
    );
};
