import { useState } from "react";
import { Avatar, Box, Button, Card, FormControl, Grid2, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { FaCopy, FaPen, FaRegCopy } from "react-icons/fa6";
import { createAdminUser } from "../../helpers/http_admin_users_requests";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";

export default function CustomerNew() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        complete_name: "",
        cpf: "",
        birth_date: "",
        whatsapp_number: "",
        email: "",
        title: "",
        observation: "",
        zip_code: "",
        address: "",
        address_number: "",
        compliment: "",
        neighborhood: "",
        city: "",
        state: "",
        profile_image: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createAdminUser(formData, () => navigate('/usuarios-administrativos'))
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const size = 400;
                    canvas.width = size;
                    canvas.height = size;
                    const ctx = canvas.getContext('2d');

                    const aspectRatio = img.width / img.height;
                    let drawWidth, drawHeight, offsetX, offsetY;

                    if (aspectRatio > 1) {
                        drawWidth = size * aspectRatio;
                        drawHeight = size;
                        offsetX = -(drawWidth - size) / 2;
                        offsetY = 0;
                    } else {
                        drawWidth = size;
                        drawHeight = size / aspectRatio;
                        offsetX = 0;
                        offsetY = -(drawHeight - size) / 2;
                    }

                    ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

                    const base64Image = canvas.toDataURL('image/jpeg');
                    setFormData((prevData) => ({
                        ...prevData,
                        profile_image: base64Image,
                    }));
                };

                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        };
    };

    return (
        <Grid2 component={'form'} onSubmit={handleSubmit} container rowSpacing={3}>
            <Grid2 size={{ xs: 12 }}>
                <Typography mt={'1rem'} fontSize={24} variant="h1">
                    Cadastrar Cliente
                </Typography>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Dados da Empresa
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'start',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Avatar
                                src={formData.profile_image}
                                sx={{
                                    width: '80px',
                                    height: '80px',
                                }}
                            />
                            <Button disabled component="label" startIcon={<FaPen size={16} />}>
                                Editar
                                <input
                                    disabled
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={handleImageUpload}
                                />
                            </Button>
                        </Box>
                        <Grid2 width={'100%'} ml={'1.5rem'} container spacing={3}>
                            <Grid2 size={{ xs: 12, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Nome Fantasia"
                                    name="fantasy_name"
                                    value={formData.fantasy_name}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, md: 4 }}>
                                <ReactInputMask
                                    mask="99.999.999/9999-99"
                                    value={formData?.cnpj || ''}
                                    onChange={handleChange}
                                >
                                    {() => (
                                        <TextField
                                            name="cnpj"
                                            fullWidth
                                            variant="standard"
                                            label="CNPJ"
                                        />
                                    )}
                                </ReactInputMask>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Inscrição Estadual"
                                    name="state_subscription"
                                    value={formData.state_subscription}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Razão Social"
                                    name="social_reason"
                                    value={formData.social_reason}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, md: 4 }}>
                                <ReactInputMask
                                    mask="(99) 9 9999-9999"
                                    value={formData?.contact_number || ''}
                                    onChange={handleChange}
                                >
                                    {() => (
                                        <TextField
                                            name="contact_number"
                                            fullWidth
                                            variant="standard"
                                            label="Número de Contato"
                                        />
                                    )}
                                </ReactInputMask>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Email Corporativo"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Dados do responsável geral
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Nome do responsável geral"
                                name="master_manager"
                                value={formData.master_manager}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <ReactInputMask
                                mask="(99) 9 9999-9999"
                                value={formData?.manager_contact_number || ''}
                                onChange={handleChange}
                            >
                                {() => (
                                    <TextField
                                        required
                                        name="manager_contact_number"
                                        fullWidth
                                        variant="standard"
                                        label="Número de Contato"
                                    />
                                )}
                            </ReactInputMask>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Email"
                                name="email"
                                value={formData.manager_email}
                                onChange={handleChange}
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>


            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Dados do responsável financeiro
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Nome do responsável financeiro"
                                name="master_manager"
                                value={formData.finance_manager}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 4 }}>
                            <ReactInputMask
                                mask="(99) 9 9999-9999"
                                value={formData?.finance_contact_number || ''}
                                onChange={handleChange}
                            >
                                {() => (
                                    <TextField
                                        required
                                        name="finance_contact_number"
                                        fullWidth
                                        variant="standard"
                                        label="Número de Contato"
                                    />
                                )}
                            </ReactInputMask>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Email"
                                name="email"
                                value={formData.finance_email}
                                onChange={handleChange}
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Catálogo e Financeiro
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <Switch /> <Button disabled startIcon={<FaRegCopy />}>Copiar Link</Button>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Cotação</InputLabel>
                                <Select
                                    fullWidth
                                    variant="standard"
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formData.quotation}
                                    label="Cotação"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Cotação A</MenuItem>
                                    <MenuItem value={20}>Cotação B</MenuItem>
                                    <MenuItem value={30}>Cotação C</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 4 }}>
                            <TextField
                                fullWidth
                                variant="standard"
                                label="Observação"
                                name="observation"
                                value={formData.observation}
                                onChange={handleChange}
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Endereço
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <ReactInputMask
                                mask="99.999-999"
                                value={formData?.zip_code || ''}
                                onChange={handleChange}
                            >
                                {() => (
                                    <TextField
                                        name="zip_code"
                                        fullWidth
                                        variant="standard"
                                        label="CEP"
                                    />
                                )}
                            </ReactInputMask>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Endereço"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Número"
                                name="address_number"
                                value={formData.address_number}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Complemento"
                                name="compliment"
                                value={formData.compliment}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Bairro"
                                name="neighborhood"
                                value={formData.neighborhood}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Cidade"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                required
                                fullWidth
                                variant="standard"
                                label="Estado"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="contained" type="submit" color="primary">Salvar Cadastro</Button>
                    <Button variant="text" sx={{ ml: 2 }} onClick={() => navigate(-1)}>
                        Voltar
                    </Button>
                </Box>
            </Grid2>
        </Grid2>
    );
};
