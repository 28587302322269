import { Box, Button, Card, Grid2, IconButton, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from 'react';
import { CustomDataTable } from "../../components/common/CustomDataTable";
import { FaKey, FaMagnifyingGlass, FaPen, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { LineChart } from "@mui/x-charts";

const columns = [
    { field: 'image', headerName: 'Foto', },
    { field: 'complete_name', headerName: 'Definir', },
    { field: 'whatsapp_number', headerName: 'Definir', },
    { field: 'cnpj', headerName: 'Definir', type: 'number', },
    { field: 'actions', headerName: 'Definir', type: 'number', },
];

const currentMonth = new Date().getMonth();

const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]

const renderActions = (row) => (
    <>
        <IconButton target='_blank' href=''>
            <FaWhatsapp color='green' />
        </IconButton>
        <Link to={`/detalhes-cliente/${row.id}`}>
            <IconButton title="Detalhes" color="primary">
                <FaMagnifyingGlass size={20} />
            </IconButton>
        </Link>
        <Link>
            <IconButton disabled title="Permissões" color="primary">
                <FaKey size={20} />
            </IconButton>
        </Link>
        <Link to={`/editar-cliente/${row.id}`}>
            <IconButton title="Edição" color="primary">
                <FaPen size={20} />
            </IconButton>
        </Link>
    </>
);

export default function Home() {

    const [orders, setOrders] = useState([]);

    const theme = useTheme();

    const LastOrders = () => (
        <Grid2 size={12}>
            <CustomDataTable
                title={"Pedido"}
                addPath={"/novo-pedido"}
                columns={columns}
                data={orders}
                actions={renderActions}
            />
        </Grid2>
    );

    const SalesGraph = () => (
        <Grid2 size={{ xs: 12, md: 9 }}>
            <Card sx={styles.salesGraphCard}>
                <Typography variant="h5">Resultado do mês de {meses[currentMonth]}</Typography>
                <LineChart
                    xAxis={[{
                        data: Array.from({ length: 15 }, (_, i) => i + 1),
                        valueFormatter: (element) =>
                            `Dia ${element}`,
                        label: 'Dias do Mês',
                    }]}
                    series={[{
                        data: [
                            5000, 7500, 8000, 9000, 10000, 12000, 13000, 14000,
                            15000, 12000, 10000, 13000, 14000, 12000, 11500
                        ],
                        valueFormatter: (value) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                        label: 'Vendas',
                    }]}
                    height={300}
                />
            </Card>
        </Grid2>
    );

    const CurrentDaySales = () => (
        <Grid2 size={{ xs: 12, md: 3 }}>
            <Card sx={styles.currentDaySalesCard}>
                <Box>
                    <Typography variant="h5" sx={{ color: theme.palette.secondary.main }}>Faturamento mensal</Typography>
                    <Typography mt={'.25rem'} mb={'.5rem'} variant="h4">R$ 3.024,00</Typography>
                    <Typography color="grey" variant="subtitle2">em 19 de Setembro, 2024</Typography>
                </Box>
                <Button startIcon={<FaMagnifyingGlass />}   sx={{ color: theme.palette.secondary.main }}>Ver mais</Button>
            </Card>
        </Grid2>
    );

    useEffect(() => {
        // getAdminUsers(setUsers);
    }, []);

    return (
        <Grid2 sx={styles.gridContainer} spacing={3} container>
            <SalesGraph />
            <CurrentDaySales />
            <LastOrders />
        </Grid2>
    );
};

const styles = {
    gridContainer: {
        height: '100%',
        width: '100%',
    },
    salesGraphCard: {
        p: '1rem',
    },
    currentDaySalesCard: {
        p: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
        minHeight: '91%',
    },
};