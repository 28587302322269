import { Avatar, Box, Button, Card, Grid2, TextField, Typography } from "@mui/material"
import { useForm, Controller } from "react-hook-form";
import { FaPen } from "react-icons/fa6";
import ReactInputMask from "react-input-mask";

import backgroundImage from "../../assets/profile_image_bg.jpg";
import { useEffect, useState } from "react";

export default function DynamicFormPage({
    title,
    details,
    initialData,
    onSubmit,
    onCancel,
    formFieldList,
    avatarUpload,
    submitButtonText = "Salvar",
    cancelButtonText = "Cancelar"
}) {

    const { control, handleSubmit, setValue, getValues } = useForm();

    const [profileImage, setProfileImage] = useState(getValues("profile_image"));

    useEffect(() => {
        if (initialData) {
            Object.keys(initialData).forEach((key) => {
                setValue(key, initialData[key]);
            });
        }
    }, [initialData, setValue]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const size = 400;
                    canvas.width = size;
                    canvas.height = size;
                    const ctx = canvas.getContext('2d');
                    const aspectRatio = img.width / img.height;
                    let drawWidth, drawHeight, offsetX, offsetY;
    
                    if (aspectRatio > 1) {
                        drawWidth = size * aspectRatio;
                        drawHeight = size;
                        offsetX = -(drawWidth - size) / 2;
                        offsetY = 0;
                    } else {
                        drawWidth = size;
                        drawHeight = size / aspectRatio;
                        offsetX = 0;
                        offsetY = -(drawHeight - size) / 2;
                    }
    
                    ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
                    const base64Image = canvas.toDataURL('image/jpeg');
                    setValue("profile_image", base64Image);
                    setProfileImage(base64Image); 
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const AvatarImageManagement = () => {
        return avatarUpload && (
            <Grid2 sx={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', borderRadius: 2, p: 2, display: 'flex', justifyContent: 'left' }} size={{ xs: 12 }} display="flex" justifyContent="center">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar src={profileImage} sx={{ width: 80, height: 80, mb: '1rem' }} />
                    {!details && (
                        <Button variant="contained" component="label" startIcon={<FaPen size={16} />}>
                            {getValues("profile_image") ? "Editar" : "Adicionar"}
                            <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
                        </Button>
                    )}
                </Box>
            </Grid2>
        );
    };

    const CustomFormBuilder = () => {

        return formFieldList?.map((block, index) => {
            return (
                <Grid2 key={index} size={{ xs: 12 }}>
                    <Card sx={{ p: '2rem 1.5rem' }}>
                        <Typography fontSize={20} mb={'1.5rem'}>
                            {block.title}
                        </Typography>
                        <Grid2 container spacing={2}>
                            {
                                block.fields.map((field, index) => {

                                    return (
                                        <Grid2 key={index} size={field.fullWidth ? { xs: 12 } : { xs: 6, md: 4 }}>
                                            <Controller
                                                name={field.name}
                                                control={control}
                                                render={({ field: controllerField }) => (
                                                    field.mask ? (
                                                        <ReactInputMask
                                                            disabled={details}
                                                            mask={field.mask}
                                                            value={controllerField.value || ''}
                                                            onChange={controllerField.onChange}
                                                        >
                                                            {() => (
                                                                <TextField
                                                                    disabled={details}
                                                                    fullWidth
                                                                    variant="standard"
                                                                    label={field.label}
                                                                    required={field.required}
                                                                />
                                                            )}
                                                        </ReactInputMask>
                                                    ) : (
                                                        <TextField
                                                            {...controllerField}
                                                            fullWidth
                                                            multiline={field.multiline}
                                                            rows={field.rows}
                                                            variant="standard"
                                                            label={field.label}
                                                            type={field.type || 'text'}
                                                            required={field.required}
                                                            InputLabelProps={field.type === 'date' ? { shrink: true } : {}}
                                                            disabled={details}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Grid2>
                                    )
                                })
                            }
                        </Grid2>
                    </Card>
                </Grid2>
            )
        });
    };

    const FormPageNavigation = () => {

        return (
            <Grid2 size={{ xs: 12 }} display="flex" justifyContent="flex-end" mt={2}>
                {
                    !details && (
                        <Button variant="contained" type="submit" color="secondary">
                            {submitButtonText}
                        </Button>
                    )
                }
                <Button variant="contained" sx={{ ml: 2 }} onClick={onCancel}>
                    {cancelButtonText}
                </Button>
            </Grid2>
        );
    };

    return (
        <Grid2 component="form" onSubmit={handleSubmit(onSubmit)} container spacing={2}>
            <Grid2 size={{ xs: 12 }}>
                <Typography mt={'1rem'} fontSize={24} variant="h1">
                    {title}
                </Typography>
            </Grid2>
            <AvatarImageManagement />
            <CustomFormBuilder />
            <FormPageNavigation />
        </Grid2>
    );
};

