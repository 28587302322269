import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AdminUsers from "./pages/AdminUsers";
import NewAdminUser from "./pages/NewAdminUser";
import UpdateAdminUser from "./pages/UpdateAdminUser";
import DetailAdminUser from "./pages/DetailAdminUser";
import RouteWrapper from "./components/RouteWrapper";
import Customers from "./pages/Customers";
import CustomerNew from "./pages/CustomerNew";
import CustomerDetails from "./pages/CustomerDetail";
import CustomerUpdate from "./pages/CustomerUpdate";
import Products from "./pages/Products";
import ProductNew from "./pages/ProductNew";
import ProductDetails from "./pages/ProductDetails";
import ProductUpdate from "./pages/ProductUpdate";
import Orders from "./pages/Orders";
import Messages from "./pages/Quotes";
import Quotes from "./pages/Quotes";
import PriceManagement from "./pages/PriceManagement";
import Profile from "./pages/Profile";

const routes = [
  {
    path: "/",
    element: <RouteWrapper isPrivate loggedComponent={<Home />} defaultComponent={<Login />} />,
  },
  {
    path: "/login",
    element: <RouteWrapper loggedComponent={<Home />} defaultComponent={<Login />} />,
  },
  {
    path: "/perfil",
    element: <RouteWrapper loggedComponent={<Profile />} defaultComponent={<Login />} />,
  },
  {
    path: "/usuarios-administrativos",
    element: <RouteWrapper isPrivate loggedComponent={<AdminUsers />} defaultComponent={<Login />} />,
  },
  {
    path: "/novo-usuario-administrativo",
    element: <RouteWrapper isPrivate loggedComponent={<NewAdminUser />} defaultComponent={<Login />} />,
  },
  {
    path: "/editar-usuario-administrativo/:id",
    element: <RouteWrapper isPrivate loggedComponent={<UpdateAdminUser />} defaultComponent={<Login />} />,
  },
  {
    path: "/detalhes-usuario-administrativo/:id",
    element: <RouteWrapper isPrivate loggedComponent={<DetailAdminUser />} defaultComponent={<Login />} />,
  },
  {
    path: "/clientes",
    element: <RouteWrapper isPrivate loggedComponent={<Customers />} defaultComponent={<Login />} />,
  },
  {
    path: "/novo-cliente",
    element: <RouteWrapper isPrivate loggedComponent={<CustomerNew />} defaultComponent={<Login />} />,
  },
  {
    path: "/detalhes-cliente/:id",
    element: <RouteWrapper isPrivate loggedComponent={<CustomerDetails />} defaultComponent={<Login />} />,
  },
  {
    path: "/editar-cliente/:id",
    element: <RouteWrapper isPrivate loggedComponent={<CustomerUpdate />} defaultComponent={<Login />} />,
  },
  {
    path: "/produtos",
    element: <RouteWrapper isPrivate loggedComponent={<Products />} defaultComponent={<Login />} />,
  },
  {
    path: "/novo-produto",
    element: <RouteWrapper isPrivate loggedComponent={<ProductNew />} defaultComponent={<Login />} />,
  },
  {
    path: "/detalhes-produto/:id",
    element: <RouteWrapper isPrivate loggedComponent={<ProductDetails />} defaultComponent={<Login />} />,
  },
  {
    path: "/editar-produto/:id",
    element: <RouteWrapper isPrivate loggedComponent={<ProductUpdate />} defaultComponent={<Login />} />,
  },
  {
    path: "/pedidos",
    element: <RouteWrapper isPrivate loggedComponent={<Orders />} defaultComponent={<Login />} />,
  },
  {
    path: "/orcamentos",
    element: <RouteWrapper isPrivate loggedComponent={<Quotes />} defaultComponent={<Login />} />,
  },
  {
    path: "/cotacao",
    element: <RouteWrapper isPrivate loggedComponent={<PriceManagement />} defaultComponent={<Login />} />,
  },
  {
    path: "*",
    element: <RouteWrapper isPrivate loggedComponent={<Home />} defaultComponent={<Login />} />,
  },
];

export default function Router() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
};