import { BrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Router from "./routes";
import { Toaster } from "react-hot-toast";
import AuthProvider from "./contexts/AuthProvider";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Layout>
            <Router />
          </Layout>
          <Toaster position="top-center" reverseOrder={false} />
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;