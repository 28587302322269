import { useEffect, useState } from "react";
import { createContext } from "react";
import { getUserData, signInAdmin } from "../helpers/http_auth_requests";
import toast from "react-hot-toast";
import { Typography } from "@mui/material";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [id, setId] = useState(null);

    async function signIn(user, password) {
        try {
            const data = await signInAdmin(user, password);

            if (data && data.token) {
                setToken(data.token);
                localStorage.setItem('user_token', data.token);

                await spreadUserData(data.token);
            }

        } catch (error) {
            console.log("Erro no processo de login:", error);
        }
    };

    async function spreadUserData(token) {
        try {
            const { user_data } = await getUserData(token);

            if (user_data) {
                setUser(user_data);
                setId(user_data.id)
                toast.success(<Typography>Seja bem-vindo, {user_data['complete_name']}!</Typography>);
            }
        } catch (error) {
            console.log("Erro ao obter dados do usuário:", error);
        };
    };

    function signOut() {
        toast.success(<Typography>Até a proxima! 😀</Typography>)
        setUser(null);
        setToken(null);
        setId(null);
        localStorage.removeItem('user_token');
    };

    useEffect(() => {

        var token = localStorage.getItem('user_token');

        if (!user && token) {
            spreadUserData(token);
        };
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                id,
                token,
                setToken,
                signIn,
                signOut,
            }} >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
