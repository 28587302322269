import { useState, useEffect } from 'react';
import { Avatar, Box, Button, Card, FormControlLabel, Grid2, Switch, TextField, Typography } from "@mui/material";
import { useParams } from 'react-router-dom';
import { getAdminUserDetails, updateAdminUser } from '../../helpers/http_admin_users_requests';
import { useNavigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';

export default function DetailAdminUser() {

    const { id } = useParams()

    const [userData, setUserData] = useState({ id: id });

    const navigate = useNavigate();

    useEffect(() => {
        getAdminUserDetails(id, setUserData);
    }, []);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateAdminUser(userData, () => navigate('/usuarios-administrativos'));
    };

    return (
        <Grid2 component={'form'} onSubmit={handleSubmit} container rowSpacing={3}>
            <Grid2 size={{ xs: 12 }}>
                <Typography mt={'1rem'} fontSize={24} variant="h1">
                    Edição de Usuário Administrador
                </Typography>
            </Grid2>

            {/* Card de Dados de Contato */}
            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Dados de Contato
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <Avatar sx={{ width: '80px', height: '80px' }} />
                        </Box>
                        <Grid2 ml={'1.5rem'} container spacing={3}>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField
                                    name="complete_name"
                                    onChange={handleChange}
                                    value={userData?.complete_name || ''}
                                    fullWidth
                                    variant="standard"
                                    label="Nome Completo"
                                />
                            </Grid2>
                            <Grid2 xs={12} md={4}>
                                <ReactInputMask
                                    mask="(99) 9 9999-9999"
                                    value={userData?.whatsapp_number || ''}
                                    onChange={handleChange}
                                >
                                    {() => (
                                        <TextField
                                            name="whatsapp_number"
                                            fullWidth
                                            variant="standard"
                                            label="Contato Principal"
                                        />
                                    )}
                                </ReactInputMask>
                            </Grid2>
                            <Grid2 size={{ xs: 12, md: 4 }}>
                                <ReactInputMask
                                    mask="999.999.999-99"
                                    value={userData?.cpf || ''}
                                    onChange={handleChange}
                                >
                                    {() => (
                                        <TextField
                                            name="cpf"
                                            fullWidth
                                            variant="standard"
                                            label="CPF"

                                        />
                                    )}
                                </ReactInputMask>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    name="email"
                                    onChange={handleChange}
                                    value={userData?.email || ''}
                                    fullWidth
                                    variant="standard"
                                    label="Email"
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    name="birth_date"
                                    onChange={handleChange}
                                    value={userData?.birth_date || ''}
                                    fullWidth
                                    variant="standard"
                                    label="Data de Nascimento"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    name="observation"
                                    onChange={handleChange}
                                    value={userData?.observation || ''}
                                    fullWidth
                                    variant="standard"
                                    label="Observação"
                                />
                            </Grid2>
                        </Grid2>
                    </Box>
                </Card>
            </Grid2>

            {/* Card de Endereço */}
            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Endereço
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <ReactInputMask
                                mask="99.999-999"
                                value={userData?.zip_code || ''}
                                onChange={handleChange}
                            >
                                {() => (
                                    <TextField
                                        name="zip_code"
                                        fullWidth
                                        variant="standard"
                                        label="CEP"
                                    />
                                )}
                            </ReactInputMask>
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="address"
                                onChange={handleChange}
                                value={userData?.address || ''}
                                fullWidth
                                variant="standard"
                                label="Endereço"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="address_number"
                                onChange={handleChange}
                                value={userData?.address_number || ''}
                                fullWidth
                                variant="standard"
                                label="Número"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="compliment"
                                onChange={handleChange}
                                value={userData?.compliment || ''}
                                fullWidth
                                variant="standard"
                                label="Complemento"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="neighborhood"
                                onChange={handleChange}
                                value={userData?.neighborhood || ''}
                                fullWidth
                                variant="standard"
                                label="Bairro"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="city"
                                onChange={handleChange}
                                value={userData?.city || ''}
                                fullWidth
                                variant="standard"
                                label="Cidade"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="state"
                                onChange={handleChange}
                                value={userData?.state || ''}
                                fullWidth
                                variant="standard"
                                label="Estado"
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Card sx={{ p: '2rem 1.5rem' }}>
                    <Typography fontSize={20} mb={'1.5rem'}>
                        Status
                    </Typography>
                    <Grid2 container spacing={2}>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <FormControlLabel
                                labelPlacement="top"
                                control={
                                    <Switch
                                        checked={Boolean(userData?.is_active)}
                                        name="is_active"
                                        onChange={handleChange}
                                    />
                                }
                                label="Status"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 3 }}>
                            <TextField
                                name="title"
                                onChange={handleChange}
                                value={userData?.title || ''}
                                fullWidth
                                variant="standard"
                                label="Cargo"
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </Grid2>

            <Grid2 size={{ xs: 12 }}>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="contained" type="submit" color="secondary">
                        Atualizar Cadastro
                    </Button>
                    <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={() => navigate(-1)}>
                        Voltar
                    </Button>
                </Box>
            </Grid2>
        </Grid2>
    );
}
