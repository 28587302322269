import { useState } from "react";
import { Box, Button, Card, FormControl, FormControlLabel, Grid2, IconButton, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { FaCircleXmark, FaPlus } from "react-icons/fa6";
import { createAdminUser } from "../../helpers/http_admin_users_requests";
import { useNavigate } from "react-router-dom";

export default function ProductNew() {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        complete_name: "",
        cpf: "",
        birth_date: "",
        whatsapp_number: "",
        email: "",
        title: "",
        observation: "",
        zip_code: "",
        address: "",
        address_number: "",
        compliment: "",
        neighborhood: "",
        city: "",
        state: "",
        profile_image: ""
    });

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createAdminUser(formData, () => navigate('/usuarios-administrativos'))
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const size = 400;
                    canvas.width = size;
                    canvas.height = size;
                    const ctx = canvas.getContext('2d');

                    const aspectRatio = img.width / img.height;
                    let drawWidth, drawHeight, offsetX, offsetY;

                    if (aspectRatio > 1) {
                        drawWidth = size * aspectRatio;
                        drawHeight = size;
                        offsetX = -(drawWidth - size) / 2;
                        offsetY = 0;
                    } else {
                        drawWidth = size;
                        drawHeight = size / aspectRatio;
                        offsetX = 0;
                        offsetY = -(drawHeight - size) / 2;
                    }

                    ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

                    const base64Image = canvas.toDataURL('image/jpeg');
                    setFormData((prevData) => ({
                        ...prevData,
                        profile_image: base64Image,
                    }));
                };

                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        };
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Card sx={{ ...style, width: 400 }}>
                    <Grid2 container spacing={3}>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant="h5">Adicionar Pedra</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <Typography color="grey">Selecione a pedra a ser adicionada na joia.</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                                <FormControlLabel
                                    value="disabled"
                                    disabled
                                    control={<Radio />}
                                    label="other"
                                />
                            </RadioGroup>
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <TextField
                                required
                                fullWidth
                                type="number"
                                variant="standard"
                                label="Quantidade"
                                name="quantity"
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, md: 6 }}>
                            <FormControl required variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">Tamanho</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Tamanho"
                                    name="gem_size"
                                >
                                    <MenuItem value={10}>Opção 1</MenuItem>
                                    <MenuItem value={20}>Opção 2</MenuItem>
                                    <MenuItem value={30}>Opção 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid2>
                        <Grid2 size={{ xs: 12}}>
                            <TextField
                                required
                                fullWidth
                                type="number"
                                variant="standard"
                                label="Descrição desta pedra"
                                name="gem_description"
                            />
                        </Grid2>
                        <Grid2 sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }} size={{ xs: 12}}>
                            <Button onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button onClick={handleClose}>
                                Adicionar pedra
                            </Button>
                        </Grid2>

                    </Grid2>
                </Card>
            </Modal>
            <Grid2 component={'form'} onSubmit={handleSubmit} container rowSpacing={3}>
                <Grid2 size={{ xs: 12 }}>
                    <Typography mt={'1rem'} fontSize={24} variant="h1">
                        Cadastrar Produto
                    </Typography>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Card sx={{ p: '2rem 1.5rem' }}>
                        <Typography fontSize={20} mb={'1.5rem'}>
                            Dados de Produto
                        </Typography>
                        <Grid2 container spacing={3}>
                            <Grid2 size={{ xs: 12 }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mb: '1rem'
                                }}>
                                    {
                                        Array(3).fill('').map((_, index) => (
                                            <Box position="relative" key={index} display="inline-block" m={1}>
                                                {/* Botão de fechar no canto superior direito */}
                                                <IconButton
                                                    size="small"
                                                    color="error"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -7,
                                                        right: -7,
                                                        zIndex: 1,
                                                        color: 'red',
                                                    }}
                                                >
                                                    <FaCircleXmark />
                                                </IconButton>

                                                {/* Imagem dentro da Box */}
                                                <Box component={'img'} height={'125px'} width={'125px'} bgcolor={'blue'} />
                                            </Box>

                                        ))
                                    }
                                </Box>
                                <Button variant="contained" component="label">
                                    Adicionar imagem
                                    <input
                                        disabled
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        onChange={handleImageUpload}
                                    />
                                </Button>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Categoria</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Categoria"
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Material</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Material"
                                        name="material"
                                        value={formData.material}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Formato</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Formato"
                                        name="format"
                                        value={formData.format}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Conforto</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Conforto"
                                        name="confort"
                                        value={formData.confort}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Largura"
                                    name="width"
                                    value={formData.width}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Espessura"
                                    name="thickness"
                                    value={formData.thickness}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Peso"
                                    type="number"
                                    name="weight"
                                    value={formData.weight}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Banho</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Banho"
                                        name="plating"
                                        value={formData.plating}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <FormControl required variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Acabamento externo</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        variant="standard"
                                        label="Acabamento externo"
                                        name="external_finishing"
                                        value={formData.external_finishing}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Opção 1</MenuItem>
                                        <MenuItem value={20}>Opção 2</MenuItem>
                                        <MenuItem value={30}>Opção 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={6}
                                    variant="standard"
                                    label="Observação"
                                    name="observation"
                                    value={formData.observation}
                                    onChange={handleChange}
                                />
                            </Grid2>
                        </Grid2>
                    </Card>
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <Card sx={{ p: '2rem 1.5rem' }}>
                        <Typography fontSize={20} mb={'1.5rem'}>
                            Pedras
                        </Typography>
                        <Grid2 size={{ xs: 12 }}>
                            <Button onClick={handleOpen} startIcon={<FaPlus />} variant="contained">
                                Adicionar Pedra
                            </Button>
                        </Grid2>
                    </Card>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Card sx={{ p: '2rem 1.5rem' }}>
                        <Typography fontSize={20} mb={'1.5rem'}>
                            Ficha técnica do produto
                        </Typography>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Nome"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Referência Interna"
                                    name="inner_reference"
                                    value={formData.inner_reference}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 6, md: 4 }}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="standard"
                                    label="Prazo de Confecção"
                                    name="production_time"
                                    value={formData.production_time}
                                    onChange={handleChange}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rows={6}
                                    variant="standard"
                                    label="Descrição"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Grid2>
                        </Grid2>
                    </Card>
                </Grid2>

                <Grid2 size={{ xs: 12 }}>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button variant="contained" type="submit" color="primary">Salvar Cadastro</Button>
                        <Button variant="text" sx={{ ml: 2 }} onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                    </Box>
                </Grid2>
            </Grid2>
        </>
    );
};
