import { createAdminUser } from "../../helpers/http_admin_users_requests";
import { useNavigate } from "react-router-dom";
import DynamicFormPage from "../../components/DynamicFormPage";

export default function NewAdminUser() {
    const navigate = useNavigate();

    const handleSubmit = (formData, e) => {
        e.preventDefault();
        createAdminUser(formData, () => navigate('/usuarios-administrativos'));
    };

    const formFieldList = [
        {
            title: "Dados de Contato",
            fields: [
                {
                    name: "complete_name",
                    label: "Complete Name",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "whatsapp_number",
                    label: "Contato Principal",
                    type: "text",
                    mask: "(99) 9 9999-9999",
                    variant: "standard"
                },
                {
                    name: "cpf",
                    label: "CPF",
                    type: "text",
                    mask: "999.999.999-99",
                    variant: "standard"
                },
                {
                    name: "email",
                    label: "Email",
                    type: "email",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "birth_date",
                    label: "Data de nascimento",
                    type: "date",
                    required: true,
                    variant: "standard",
                    InputLabelProps: { shrink: true }
                },
                {
                    name: "title",
                    label: "Cargo",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "observation",
                    label: "Observação",
                    type: "text",
                    multiline: true,
                    rows: 5,
                    fullWidth: true,
                    variant: "standard"
                }
            ]
        },
        {
            title: "Endereço",
            fields: [
                {
                    name: "zip_code",
                    label: "CEP",
                    type: "text",
                    mask: "99.999-999",
                    variant: "standard"
                },
                {
                    name: "address",
                    label: "Endereço",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "address_number",
                    label: "Número",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "compliment",
                    label: "Complemento",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "neighborhood",
                    label: "Bairro",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "city",
                    label: "Cidade",
                    type: "text",
                    required: true,
                    variant: "standard"
                },
                {
                    name: "state",
                    label: "Estado",
                    type: "text",
                    required: true,
                    variant: "standard"
                }
            ]
        }
    ];

    return <DynamicFormPage title={"Usuário Administrador - Cadastro"} formFieldList={formFieldList} avatarUpload onCancel={() => navigate(-1)} onSubmit={handleSubmit} />;

};
