import { useState, useEffect } from 'react';
import { IconButton } from "@mui/material";
import { FaEnvelope, FaKey, FaMagnifyingGlass, FaPen, FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import ListingPage from '../../components/DynamicListingPage';

export default function Quotes() {

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        // getAdminUsers(setUsers);
    }, []);

    const columns = [
        { field: 'image', headerName: 'Foto', },
        {
            field: 'complete_name',
            headerName: 'Definir',
        },
        {
            field: 'whatsapp_number',
            headerName: 'Definir',
        },
        {
            field: 'cnpj',
            headerName: 'Definir',
            type: 'number',
        },
        {
            field: 'actions',
            headerName: 'Definir',
            type: 'number',
        },
    ];

    const renderActions = (row) => (
        <>
            <IconButton target='_blank' href=''>
                <FaWhatsapp color='green' />
            </IconButton>
            <Link to={`/detalhes-cliente/${row.id}`}>
                <IconButton title="Detalhes" color="primary">
                    <FaMagnifyingGlass size={20} />
                </IconButton>
            </Link>
            <Link>
                <IconButton disabled title="Permissões" color="primary">
                    <FaKey size={20} />
                </IconButton>
            </Link>
            <Link to={`/editar-cliente/${row.id}`}>
                <IconButton title="Edição" color="primary">
                    <FaPen size={20} />
                </IconButton>
            </Link>
        </>
    );

    return <ListingPage icon={FaEnvelope} pageTitle={"Orçamentos Inovart Gold"} disabledAddButton addButtonTitle={"Pedido"} addPath={"/novo-pedido"} columns={columns} data={orders} actions={renderActions} />

};