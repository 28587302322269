import { Box, Grid2, Typography, useTheme } from "@mui/material";
import { CustomDataTable } from "../common/CustomDataTable";

export default function DynamicListingPage({
    icon,
    pageTitle,
    addButtonTitle,
    columns,
    data,
    actions,
    addPath,
    disabledAddButton
}) {

    const theme = useTheme();

    return (
        <Grid2 container>
            <Grid2 sx={{
                display: 'flex'
            }} size={12}>
                <Typography mt={'1rem'} mb={'2rem'} fontSize={24} variant="h1">
                    {icon && <Box color={theme.palette.primary.light} size={32} component={icon} mr={'.75rem'} />}
                    {pageTitle}
                </Typography>
            </Grid2>
            <Grid2 size={12}>
                <CustomDataTable
                    title={addButtonTitle}
                    addPath={addPath}
                    columns={columns}
                    data={data}
                    actions={actions}
                    disabledAddButton={disabledAddButton}
                />
            </Grid2>
        </Grid2>
    );
};