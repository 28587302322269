import { useState, useEffect } from 'react';
import {  IconButton} from "@mui/material";
import { FaKey, FaMagnifyingGlass, FaPen, FaRegCircle, FaRegCircleCheck, FaUsers } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { getAdminUsers } from '../../helpers/http_admin_users_requests';
import ListingPage from '../../components/DynamicListingPage';

export default function AdminUsers() {

    const [users, setUsers] = useState([]);

    useEffect(() => {
        getAdminUsers(setUsers)
    }, []);

    const columns = [
        { field: 'image', headerName: 'Foto', },
        {
            field: 'complete_name',
            headerName: 'Nome',
        },
        {
            field: 'whatsapp_number',
            headerName: 'Contato',
        },
        {
            field: 'title',
            headerName: 'Cargo',
            type: 'number',
        },
        {
            field: 'actions',
            headerName: 'Ações',
            type: 'number',
        },
    ];

    const CurrentSwitch = ({ status }) => {

        return status == 1 ? <FaRegCircleCheck color='green' size={20} /> : <FaRegCircle color='gray' size={20} />;

    };

    const renderActions = (row) => (
        <>
            <IconButton disabled title="Status" color="primary">
                <CurrentSwitch status={row.is_active} />
            </IconButton>
            <Link to={`/detalhes-usuario-administrativo/${row.id}`}>
                <IconButton title="Detalhes" color="primary">
                    <FaMagnifyingGlass size={20} />
                </IconButton>
            </Link>
            <Link>
                <IconButton disabled title="Permissões" color="primary">
                    <FaKey size={20} />
                </IconButton>
            </Link>
            <Link to={`/editar-usuario-administrativo/${row.id}`}>
                <IconButton title="Edição" color="primary">
                    <FaPen size={20} />
                </IconButton>
            </Link>
        </>
    );

    return <ListingPage icon={FaUsers} pageTitle={"Usuários Inovart Gold"} addButtonTitle={"usuário"} addPath={"/novo-usuario-administrativo"} columns={columns} data={users} actions={renderActions} />
};