import { useState } from 'react';
import { Avatar, Box, Card, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from './CustomButton';
import { FaMagnifyingGlass, FaPlus } from 'react-icons/fa6';

export function CustomDataTable({ title, addPath, columns, data, actions, disabledAddButton }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = data.filter(row =>
        columns.some(column =>
            String(row[column.field] || '').toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const rowsToDisplay = [...filteredData];
    
    while (rowsToDisplay.length < 5) {
        rowsToDisplay.push({ id: null });
    };

    const CustomTableHead = () => {

        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.field}
                            align={column.field === 'actions' ? 'center' : 'left'}
                        >
                            <Typography fontWeight={600}>{column.headerName}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const CustomTableBody = () => {
        return (
            <TableBody>
                {rowsToDisplay.map((row, index) => (
                    <TableRow key={row.id || index}>
                        {columns.map((column) => (
                            <TableCell
                                key={column.field}
                                align={column.field === 'actions' ? 'center' : 'left'}
                            >
                                {row.id ? (
                                    column.field === 'image' ? (
                                        <Avatar alt={row.complete_name} src={row.image} />
                                    ) : column.field === 'actions' ? (
                                        actions(row)
                                    ) : (
                                        row[column.field]
                                    )
                                ) : (
                                    <div style={{ height: 40 }} />
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    const CustomButtonArea = () => {
        return !disabledAddButton && <CustomButton color="secondary" startIcon={<FaPlus />} component={Link} to={addPath} title={`Adicionar ${title}`} />
    };

    return (
        <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '1rem' }}>
                <TextField
                    slotProps={{
                        input: {
                            endAdornment:
                                <InputAdornment position="end">
                                    <FaMagnifyingGlass />
                                </InputAdornment>
                        },
                    }}
                    fullWidth
                    sx={{
                        mr: disabledAddButton? 0 : '1rem'
                    }}
                    size="small"
                    label="Pesquisar"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <CustomButtonArea />
            </Box>
            <Box sx={{ overflowX: 'scroll' }}>
                <Table>
                    <CustomTableHead />
                    <CustomTableBody />
                </Table>
            </Box>
        </Card>
    );
};