import { AppBar, Badge, IconButton, Toolbar, useTheme } from "@mui/material";
import { useContext } from "react";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import { FaCircleUser, FaEnvelope, FaUser } from "react-icons/fa6";
import { AuthContext } from "../../contexts/AuthProvider";
import { Link } from "react-router-dom";

export default function Header({ handleVisibilityChange }) {

    const { signOut } = useContext(AuthContext);

    const theme = useTheme();

    return (
        <AppBar position="fixed" sx={{
            bgcolor: theme.palette.primary.light
        }} >
            <Toolbar sx={{
                ml: {
                    xs: 0,
                    md: '240px'
                },
                height: '4rem',
                display: 'flex',
                justifyContent: {
                    xs: 'space-between',
                    md: 'end'
                }
            }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleVisibilityChange}
                    sx={{
                        mr: 2, display: {
                            xs: 'block',
                            md: 'none'
                        }
                    }}
                >
                    <FaBars />
                </IconButton>
                <IconButton disabled color="inherit">
                    <Badge badgeContent={4} color="error">
                        <FaEnvelope />
                    </Badge>
                </IconButton>
                <IconButton LinkComponent={Link} to="/perfil" color="inherit"><FaCircleUser /></IconButton>
                <IconButton color="inherit" onClick={signOut}><FaSignOutAlt /></IconButton>
            </Toolbar>
        </AppBar>
    );
};